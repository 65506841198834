import React, { useState } from 'react';
import IceCreamGrid from './components/IceCreamGrid';

const defaultFlavors = [
  'Vanilla',
  'Chocolate',
  'Strawberry',
  'Mint Chocolate Chip',
  'Pistachio',
  'Cookie Dough',
  'Brownie Dough',
  'Mango Sorbet (v)',
  'Blueberry Cobbler',
  'Orange Dream Cream',
  'Coffee Chip',
  'Vanilla Oreo',
];

function App() {
  const [flavors, setFlavors] = useState([]);
  const [input, setInput] = useState('');
  const [gradientBackground, setGradientBackground] = useState(false);
  const [textAlignCenter, setTextAlignCenter] = useState(false);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFlavors(input.split(',').map((flavor) => flavor.trim()));
    setInput('');
  };

  const handleAddDefaultFlavors = () => {
    setFlavors(defaultFlavors);
  };

  const handleChangeBackground = () => {
    setGradientBackground(gradientBackground ? false : true);
  };

  const handleChangeTextAlign = () => {
    setTextAlignCenter(textAlignCenter ? false : true);
  };

  return (
    <div className='app'>
      {flavors.length === 0 ? (
        <div className='input-flavors'>
          <h1>Add Flavors:</h1>
          <button
            className='button-56 default-btn'
            onClick={handleAddDefaultFlavors}
          >
            Use Default Flavors
          </button>
          <form onSubmit={handleSubmit} className='flex'>
            <div className='input-wrapper'>
              <input
                className='flavor-input'
                type='text'
                value={input}
                onChange={handleInputChange}
                placeholder='Enter ice cream flavors, separated by commas'
              />
              <button className='button-56' type='submit'>
                Submit
              </button>
            </div>
            <div className='options'>
              <h3>Options:</h3>
              <div className='option'>
                <input
                  onChange={handleChangeBackground}
                  type='checkbox'
                  name='gradient-background'
                />
                <label htmlFor='gradient-background'>Gradient Background</label>
              </div>
              <div className='option'>
                <input
                  onChange={handleChangeTextAlign}
                  type='checkbox'
                  name='text-align-center'
                />
                <label htmlFor='text-align-center'>Text Align Center</label>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <IceCreamGrid
          flavors={flavors}
          setFlavors={setFlavors}
          gradientBackground={gradientBackground}
          textAlignCenter={textAlignCenter}
        />
      )}
    </div>
  );
}

export default App;
