import React, { useState } from 'react';
import EditableText from './EditableText';

const IceCreamGrid = ({
  flavors,
  setFlavors,
  gradientBackground,
  textAlignCenter,
}) => {
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [title, setTitle] = useState('Edit This Title');

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditValue(flavors[index]);
  };

  const handleEditChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleEditSubmit = (index) => {
    const updatedFlavors = [...flavors];
    updatedFlavors[index] = editValue.trim();
    setFlavors(updatedFlavors);
    setEditIndex(null);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      handleEditSubmit(index);
    }
  };

  const handleEditTitle = (newTitle) => {
    if (newTitle !== '') {
      setTitle(newTitle);
    } else {
      alert('Title cannot be empty!');
    }
  };

  return (
    <div className={`flavor-board ${gradientBackground ? 'gradient' : ''}`}>
      <h1>
        <EditableText text={title.toUpperCase()} onTextEdit={handleEditTitle} />
      </h1>
      <div className={`flavor-grid ${textAlignCenter ? 'center' : ''}`}>
        {flavors.map((flavor, index) => (
          <div key={index} className='flavor-item'>
            {editIndex === index ? (
              <input
                type='text'
                value={editValue}
                onChange={handleEditChange}
                onBlur={() => handleEditSubmit(index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                autoFocus
              />
            ) : (
              <div onClick={() => handleEdit(index)}>
                <div className='flavor-text'>
                  <span>
                    <svg
                      width='0.75em'
                      viewBox='0 0 124 202'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M97.0057 100.643C102.805 87.1699 107.368 73.8766 107.391 59.6178C107.268 53.3713 106.75 47.1389 105.84 40.9577C105.362 38.2014 104.499 35.5256 103.277 33.0087C100.737 27.2378 99.8379 26.6091 93.4541 25.5986C95.9941 21.1076 98.4893 17.1106 100.827 12.8891C103.165 8.66759 105.323 4.46853 107.571 0C110.99 1.19687 113.821 3.65538 115.483 6.8712C119.311 13.6345 121.749 21.0931 122.654 28.8096C125.964 52.1084 123.144 75.8668 114.471 97.7462C112.988 101.586 111.572 105.493 109.773 109.176C108.955 110.56 108.523 112.138 108.523 113.745C108.523 115.353 108.955 116.931 109.773 118.315C115.24 129.058 117.145 141.259 115.214 153.154C113.282 165.05 107.614 176.024 99.0288 184.49C82.1025 201.039 61.7371 206.203 39.3036 198.501C16.8701 190.799 3.78767 174.564 0.550775 151.032C-1.0401 139.739 0.834427 128.229 5.92713 118.022C11.0198 107.815 19.0913 99.3911 29.0759 93.8615C50.7227 81.8481 72.0546 83.3077 92.4875 97.2522C93.8587 98.1729 95.14 99.2058 97.0057 100.643ZM86.8454 145.732C88.2615 144.385 89.408 143.801 89.7227 142.925C92.0018 137.223 92.5719 130.982 91.3636 124.962C90.9957 123.096 90.0444 121.394 88.6467 120.103C87.2489 118.812 85.4771 117.997 83.5861 117.776C79.3377 116.99 75.2241 117.394 72.0097 121.436C79.5072 127.646 84.726 136.169 86.8454 145.665V145.732Z'
                        fill='#D80001'
                      />
                    </svg>
                  </span>
                  {flavor.toUpperCase()}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IceCreamGrid;
